import { createSelector } from 'reselect'
import { LOCATION_CHANGE } from 'connected-react-router'
export const initState = {
    qty: null,
    totalPrice: null,
    totalWithShippingTaxes: null,
    totalWithShippingTaxesWithDiscount:null,
    isAdding: false,
    showModal: false,
    lines: [],
    linesBySeller: [],
    selectedShippingMethodBySeller: [],
    alert: null,
    status: null,
    monthItems: null,
    waitTillDate: null,
    reservationCode: null,
    isSending: false,
    comment: null,
    linesPrice: [],
    calculatingLinesPrice: false,
    phase2PricesByProduct: [],
    totalCoin: null,
    vouchers: [],
    voucherStatusText: '',
    isValidVoucher: null,
    isLoadingVoucher: false,
    showNoStockModal: false,
    matsNoStock: [],
    showIncompatibleTypesExceptionModal: false,
    checkoutResponse: [],
    showCheckoutModal:false,
    cartType: null,
    checkoutError: null,
}

const cart = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, { showModal: false, showCheckoutModal: false, alert: null, checkoutError: null, checkoutResponse: null })
        case 'SHOW_CART_MODAL':
            return Object.assign({}, state, { showModal: true })
        case 'CLOSE_CART_MODAL':
            return Object.assign({}, state, { showModal: false })
        case 'FETCH_CART_SUCCESS':
            return Object.assign({}, state, {
                lines: action.cart.lines,
                linesBySeller: action.cart.bySeller,
                totalPrice: action.cart.price,
                totalWithShippingTaxes: action.cart.totalWithShippingTaxes,
                totalWithShippingTaxesWithDiscount: action.cart.totalWithShippingTaxesWithDiscount,
                cartType: action.cart.type,
                vouchers: action.cart.vouchers,
            })
        case 'EMPTY_CART':
            return { ...state, lines: [] }
        case 'ADD_CART_LINE_REQUEST':
            return { ...state, isAdding: true }
        case 'REMOVE_CART_LINE':
            return { ...state, lines: state.lines.filter((lines) => lines.lineId !== action.lineId) }
        case 'ADD_CART_LINE_FAILURE':
            return { ...state, alert: `Add To cart Error: ${action.payload.status} ${action.payload.statusText}`, isAdding: false }
        case 'ADD_CART_LINE_SUCCESS':
            return { ...state, isAdding: false, isAddingCallApi: false }
        case 'ADD_CART_LINE':
            return { ...state, lines: [action.line].concat(state.lines) }
        case 'CHANGE_CART_LINE_QTY':
            return {
                ...state,
                lines: state.lines.map((line) => {
                    if (line.lineId === action.lineId) {
                        return { ...line, qty: action.qty }
                    }
                    return line
                }),
            }
        case 'RESET_UI':
            return Object.assign({}, state, { showModal: false })
        case 'GET_CHECKOUT_SUMMARY_SUCCESS':
            return Object.assign({}, state, { checkoutResponse: action.checkoutResponse, checkoutError: null })
        case 'SUBMIT_CHECKOUT_SUCCESS':
            return Object.assign({}, state, { checkoutResponse: action.checkoutResponse, checkoutError: null })
        case 'SUBMIT_CHECKOUT_FAILURE':
            return Object.assign({}, state, {
                checkoutResponse: action.checkoutResponse,
                checkoutError: {
                    errorCode: action.errorCode,
                    errors: action.errors,
                },
            })
        case 'SHOW_CHECKOUT_MODAL':
            return Object.assign({}, state, { showCheckoutModal: true })
        case 'CLOSE_CHECKOUT_MODAL':
            return Object.assign({}, state, { showCheckoutModal: false })
        case 'CHANGE_SHIPPING_METHOD_BY_SELLER':
            return Object.assign({}, state, {
                selectedShippingMethodBySeller: { ...state.selectedShippingMethodBySeller, [action.sellerName]: action.shippingMethod },
            })
        case 'CART_CHECK_VALID_VOUCHER_REQUEST':
        case 'CART_REMOVE_VOUCHER_REQUEST':
            return { ...state, isLoadingVoucher: true }
        case 'CART_CHECK_VALID_VOUCHER_SUCCESS':
        case 'CART_REMOVE_VOUCHER_SUCCESS':
            return {
                ...state,
                isLoadingVoucher: false,
                voucherStatusText: '',
                lines: action.cart.lines,
                vouchers: action.cart.vouchers,
                totalPrice: action.cart.price,
                totalWithShippingTaxes: action.cart.totalWithShippingTaxes,
                totalWithShippingTaxesWithDiscount: action.cart.totalWithShippingTaxesWithDiscount,
            }
        case 'CART_CHECK_VALID_VOUCHER_FAILURE':
        case 'CART_REMOVE_VOUCHER_FAILURE':
            return {
                ...state,
                isLoadingVoucher: false,
                voucherStatusText: action.voucherStatusText,
                isValidVoucher: action.isValidVoucher,
            }

        case 'CART_CHECK_VALID_VOUCHER_RESET':
            return { ...state, vouchers: [], isLoadingVoucher: false, isValidVoucher: null, voucherStatusText: '' }
        default:
            return state
    }
}
export const showModalSelector = (state) => {
    return state.cart.showModal
}

export const getLines = (state) => {
    return state.cart.lines
}

export const getCartType = (state) => {
    return state.cart.cartType
}

export const getIsAdding = (state) => {
    return state.cart.isAdding
}

export const getAlert = (state) => {
    return state.cart.alert
}

export const getReservationCode = (state) => {
    return state.cart.reservationCode
}

export const isSending = (state) => {
    return state.cart.isSending
}

export const byLineId = (state, lineId) => {
    let found = state.cart.lines.find((line) => {
        return line.lineId === lineId
    })

    return found
}
const linesSelector = (state) => {
    return state.cart.lines
}

export const getShippingMethodsOptionsBySeller = (state) => {
    return state.cart.shippingMethodsOptionsBySeller
}

export const selectedShippingMethodBySeller = (state) => {
    return state.cart.bySeller.shippingMethod
}
export const getLinesBySellerFromBackend = (state) => {
    return state.cart.linesBySeller
}
export const getLinesBySeller = (state) => {
    //REVIEW: lines grouped by seller from back this aint needed anymore.
    let group = state.cart.lines.reduce((r, a) => {
            r[a.item.sellerName] = [...(r[a.item.sellerName] || []), a]
            return r;
        }, {});
      
        // group =Object.entries(group).map((sellerItems) => {
        //     let totalItemsBySeller = 0
        //     let totalItemsPriceBySeller = 0
        //     let totalItemsShippingPrice = 0
        //     sellerItems.map((item) => {
        //         totalItemsBySeller = totalItemsBySeller + item.qty
        //         totalItemsPriceBySeller = totalItemsPriceBySeller + item.qty * item.price
        //     })
        //     totalItemsShippingPrice = totalItemsBySeller > 2 ? 3 : 1.2
        //     sellerItems.totalItemsBySeller = totalItemsBySeller
        //     sellerItems.totalItemsPriceBySeller = totalItemsPriceBySeller
        //     sellerItems.totalItemsShippingPrice = totalItemsShippingPrice
        //     return sellerItems;
        // })
    return group
}
export const getVoucher = (state) => (state.cart.vouchers ? state.cart.vouchers[0] : null)
export const getVoucherStatusText = (state) => state.cart.voucherStatusText
export const getIsValidVoucher = (state) => (state.cart.vouchers && !!state.cart.vouchers[0] ? true : state.cart.isValidVoucher)
export const getIsLoadingVoucher = (state) => state.cart.isLoadingVoucher
export const getCheckoutResponse = (state) => {
    return state.cart.checkoutResponse
}
export const totalSelector = (state) => {
    return state.cart.totalAmount
}
export const totalWithShippingTaxesSelector = (state) => {
    return state.cart.totalWithShippingTaxes
}
export const totalWithShippingTaxesWithDiscount = (state) => {
    return state.cart.totalWithShippingTaxesWithDiscount
}
export const showCheckoutModalSelector = (state) => {
    return state.cart.showCheckoutModal
}
export const qtySelector = createSelector(linesSelector, (lines) => parseFloat(lines.reduce((total, line) => total + line.qty, 0).toFixed(2)))
export const totalPrice = (state) => {
    return state.cart.totalPrice
}
export const sellersSelector = (state) => {
    let sellers = []
    state.cart.lines.forEach(line => {
        if(!sellers.includes(line.sellerName)){
            sellers.push(line.sellerName)
        }
    })
    return sellers.length
}

export const checkoutError = (state) => {
    return state.cart.checkoutError;
}

export default cart
