import { push } from 'connected-react-router';
import { createChat } from '../../store/chats/actions';
import { getShippingAddress, getUserId, getUserName } from '../../store/login/reducers'
import { changeShippingMethodBySeller } from '../../store/cart/actions'
import { checkoutError, totalPrice, totalWithShippingTaxesSelector, totalWithShippingTaxesWithDiscount } from '../../store/cart/reducers'
import { connect } from 'react-redux'
import { checkout, emptyCart, fetchCart, removeCartLinesFromSeller } from '../../store/cart/actions'
import {
    getCartType,
    getLines,
    getLinesBySeller,
    getLinesBySellerFromBackend,
    getCheckoutResponse,
    qtySelector,
    sellersSelector,
    showModalSelector
} from '../../store/cart/reducers'
import { injectIntl } from 'react-intl'

import { getCredit } from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import Cart from './Cart'

const mapStateToProps = state => {
    return {
        lines: getLines(state),
        cartType: getCartType(state),
        linesBySeller: getLinesBySeller(state),
        linesBySellerFromBackend: getLinesBySellerFromBackend(state),
        totalPrice: totalPrice(state),
        totalWithShippingTaxes: totalWithShippingTaxesSelector(state),
        totalWithShippingTaxesWithDiscount: totalWithShippingTaxesWithDiscount(state),
        totalQty: qtySelector(state),
        show: showModalSelector(state),
        credit: getCredit(state),
        totalSellers: sellersSelector(state),
        lang: getLanguage(state),
        checkoutResponse: getCheckoutResponse(state),
        shippingAddress: getShippingAddress(state),
        userLoggedId: getUserId(state),
        userName: getUserName(state),
        checkoutError: checkoutError(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCart: () => {
            dispatch(fetchCart())
        },
        checkout: () => {
            dispatch(checkout())
        },
        changeShippingMethodBySeller: (sellerName, shippingMethod, dropOffStoreId) => {
            dispatch(changeShippingMethodBySeller(sellerName, shippingMethod, dropOffStoreId))
        },
        emptyCart: () => {
            dispatch(emptyCart())
        },
        createChat: (userId, sellerId) => {
            dispatch(createChat(userId, sellerId))
        },
        removeLinesFromSeller: (sellerId) => {
            dispatch(removeCartLinesFromSeller(sellerId))
        },
        redirect: (url) => Promise.resolve(dispatch(push(url))),
    }
}

const CartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart)

export default injectIntl(CartContainer)
